import './App.css'
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'

// Component Imports
import Navbar from './components/NavBar/Navbar'
import Landing from './components/Landing/Landing'
import Contact from './components/Contact/Contact'
import About from './components/About/About'
import Footer from './components/Footer/Footer'
import Return from './components/Return/Return'
import Terms from './components/Terms/Terms'
import Privacy from './components/Privacy/Privacy'

// Starts the page at the top whenever we route
import ScrollToTop from './scrollToTop'

function App() {
    return (
        <Router>
            <ScrollToTop />
            <div className="app">
                <Navbar />
                <Routes>
                    <Route
                        path="/"
                        element={<Landing />}
                    />
                    <Route
                        path="/contact"
                        element={<Contact />}
                    />
                    <Route
                        path="/about"
                        element={<About />}
                    />
                    <Route
                        path="/terms-and-conditions"
                        element={<Terms />}
                    />
                    <Route
                        path="/privacy-policy"
                        element={<Privacy />}
                    />
                    <Route
                        path="/return"
                        element={<Return />}
                    />
                </Routes>
                <Footer />
            </div>
        </Router>
    )
}

export default App
