import React from 'react'
import './Footer.css'
import { Link } from 'react-router-dom'
// Socials Icons
import { FaInstagram, FaFacebookF, FaLinkedinIn, FaTwitter, FaRedditAlien } from 'react-icons/fa'

import logo from '../../assets/footer-logo.png'

export default function Footer() {
    return (
        <footer className="footer-container">
            <div className="footer-logo-contact">
                <div className="footer-logo">
                    <Link to={'/'}>
                        <img
                            src={logo}
                            alt="Logo"
                        />
                    </Link>
                </div>
                <div className="footer-divider"></div>
                <div className="footer-email-container">
                    <p>Contact Us:</p>
                    <p className="footer-email">
                        <a href="mailto:contact@joinparket.com">contact@joinparket.com</a>
                    </p>
                </div>
            </div>
            <div className="footer-socials">
                <a
                    href="https://www.instagram.com/joinparket"
                    target="_blank"
                    rel="noopener noreferrer"
                    title="Visit our Instagram profile"
                    aria-label="Instagram profile">
                    <FaInstagram />
                </a>
                <a
                    href="https://www.facebook.com/joinparket"
                    target="_blank"
                    rel="noopener noreferrer"
                    title="Visit our Facebook profile"
                    aria-label="Facebook profile">
                    <FaFacebookF />
                </a>
                <a
                    href="https://www.linkedin.com/company/joinparket"
                    target="_blank"
                    rel="noopener noreferrer"
                    title="Visit our LinkedIn profile"
                    aria-label="LinkedIn profile">
                    <FaLinkedinIn />
                </a>
                <a
                    href="https://twitter.com/joinparket"
                    target="_blank"
                    rel="noopener noreferrer"
                    title="Visit our Twitter profile"
                    aria-label="Twitter profile">
                    <FaTwitter />
                </a>
                <a
                    href="https://www.reddit.com/user/joinparket"
                    target="_blank"
                    rel="noopener noreferrer"
                    title="Visit our Reddit profile"
                    aria-label="Reddit profile">
                    <FaRedditAlien />
                </a>
            </div>
            <div className="footer-links">
                <div className="links-col">
                    <p className="link-col-title">Company</p>
                    <Link
                        className="link"
                        to="contact"
                        aria-label="Waitlist page">
                        Waitlist
                    </Link>
                    <Link
                        className="link"
                        to="about"
                        aria-label="About page">
                        About Us
                    </Link>
                    <Link
                        className="link"
                        to="/"
                        aria-label="Home page">
                        Home
                    </Link>
                </div>
                <div className="links-col">
                    <p className="link-col-title">Legal</p>
                    <Link
                        className="link"
                        to="terms-and-conditions"
                        target="_blank"
                        rel="noopener noreferrer"
                        aria-label="Terms and conditions page">
                        Terms of Service
                    </Link>
                    <Link
                        className="link"
                        to="privacy-policy"
                        target="_blank"
                        rel="noopener noreferrer"
                        aria-label="Privacy policy page">
                        Privacy Policy
                    </Link>
                    <Link
                        className="link"
                        to="terms-and-conditions#section5"
                        target="_blank"
                        rel="noopener noreferrer"
                        aria-label="Refund policy page">
                        Refund Policy
                    </Link>
                </div>
            </div>
        </footer>
    )
}
