import React from 'react'

import './About.css'
import delandPhoto from '../../assets/delandPhoto.webp'
import abdullahPhoto from '../../assets/abdullahPhoto.webp'
import milesPhoto from '../../assets/milesPhoto.webp'
import melikPhoto from '../../assets/melikPhoto.webp'

export default function About() {
    return (
        <div className="about-page-container">
            <div className="photos-banner">
                <img
                    className="photos-img"
                    src={delandPhoto}
                    alt="Deland"
                />
                <img
                    className="photos-img"
                    src={abdullahPhoto}
                    alt="Abdullah"
                />
                <img
                    className="photos-img"
                    src={milesPhoto}
                    alt="Miles"
                />
                <img
                    className="photos-img"
                    src={melikPhoto}
                    alt="Melik"
                />
            </div>
            <h1 className="about-heading">About Us</h1>
            <h1 className="about-subheading">Who are we?</h1>
            <div className="about-text">
                <p>Based in NYC. We're a team of driven engineers, committed to addressing real-world challenges our communities face everyday.</p>
                <p>
                    Parket was born from our own experiences with the daily frustrations of parking—the endless search for an open spot, relocating cars for street cleaning, and seeing that ugly
                    yellow parking ticket on the windshield.
                </p>
            </div>
            <h1 className="about-subheading">How did we start?</h1>
            <div className="about-text">
                <p>The idea for Parket originated when we noticed neighbors renting out their underused driveways for extra cash.</p>
                <p>
                    We spoke with other homeowners with available spaces, but they were hesitant to rent them out due to the effort involved: advertising, vetting drivers, and managing payments,
                    compounded by the inconsistent availability of their spaces.
                </p>
                <p>
                    Recognizing these barriers, we developed Parket to streamline the entire process. Our platform simplifies advertising, handles payments, and meets the diverse needs of both drivers
                    and hosts, optimizing the use of spaces in a sustainable manner.
                </p>
                <p>Over the last two years, while balancing jobs and school, we've dedicated ourselves to solving a prevalent issue within our communities.</p>
                <p>Our vision ends when the problem ends.</p>
                <h3
                    className="about-sign-off"
                    style={{ color: '#316cf6' }}>
                    — Deland, Abdullah, Miles, and Melik
                </h3>
            </div>
        </div>
    )
}
