import React from 'react'
import './Contact.css'
import { useLocation } from 'react-router-dom'
import axios from 'axios'
import modalLogo from '../../assets/driver-icon-2.png'

function SubmissionModal({ isOpen, close }) {
    if (!isOpen) return null

    return (
        <div
            className="modal-overlay"
            onClick={close}>
            <div
                className="modal-content"
                onClick={(e) => e.stopPropagation()}>
                <img
                    className="modal-logo"
                    src={modalLogo}
                    alt="modal-logo"
                />
                <p>Your submission was successful! We'll update you soon.</p>
                <p style={{ color: '#316cf6' }}>(make sure to check your spam!)</p>
                <button onClick={close}>Close</button>
            </div>
        </div>
    )
}

export default function Contact() {
    let { state } = useLocation()

    const [formData, setFormData] = React.useState({
        firstName: '',
        lastName: '',
        email: '',
        category: 'Driver',
        location: '',
        interests: '',
        phone: '',
    })

    const [submitted, setSubmitted] = React.useState(false)

    React.useEffect(() => {
        if (state?.email) {
            setFormData({
                ...formData,
                email: state.email,
            })
        }
    }, [state?.email, formData])

    const handleChange = (e) => {
        // Reset the email/phone validation so submit will work after the first failure
        const emailField = document.getElementById('email')
        emailField.setCustomValidity('')
        emailField.reportValidity()

        const { name, value } = e.target

        setFormData({
            ...formData,
            [name]: name === 'phone' ? formatPhoneNumber(value) : value,
        })
    }

    const handleSubmit = (e) => {
        e.preventDefault()

        if (!contactProvided(formData?.email, formData?.phone)) {
            return
        }

        // Normalize data for Sheets
        const data = {
            Date: new Date().toLocaleString(),
            Email: formData?.email,
            Phone: formData?.phone,
            FirstName: formData?.firstName,
            LastName: formData?.lastName,
            Category: formData?.category,
            Location: formData?.location,
            Interests: formData?.interests,
        }

        axios.post(process.env.REACT_APP_SHEET_BEST_API_KEY, data).then((res) => {
            setFormData({
                email: '',
                phone: '',
                firstName: '',
                lastName: '',
                category: 'Driver',
                location: '',
                interests: '',
            })
            setSubmitted(true)
        })
    }

    const formatPhoneNumber = (phoneNumber) => {
        const cleanedPhoneNumber = phoneNumber.replace(/\D/g, '') // Remove non-numeric characters
        if (cleanedPhoneNumber.length <= 3) {
            return cleanedPhoneNumber
        } else if (cleanedPhoneNumber.length <= 6) {
            return `${cleanedPhoneNumber.slice(0, 3)}-${cleanedPhoneNumber.slice(3)}`
        } else {
            return `${cleanedPhoneNumber.slice(0, 3)}-${cleanedPhoneNumber.slice(3, 6)}-${cleanedPhoneNumber.slice(6, 10)}`
        }
    }

    const contactProvided = (email, phoneNumber) => {
        const good = email || phoneNumber
        const emailField = document.getElementById('email')
        if (!good) {
            emailField.setCustomValidity('You have to enter an email or a phone number.')
            emailField.reportValidity()
        } else {
            emailField.setCustomValidity('')
            emailField.reportValidity()
        }
        return good
    }

    return (
        <div className="contact-page-container">
            <SubmissionModal
                isOpen={submitted}
                close={() => setSubmitted(false)}
            />
            <h2 style={{ marginBottom: 10 }}>Join Our Waitlist</h2>
            <p style={{ padding: '0 30px' }}>Enter your email and we'll keep you updated on our release!</p>
            <form
                className="contact-form"
                onSubmit={handleSubmit}>
                <div className="first-name-input">
                    <label htmlFor="firstName">
                        First Name<span style={{ color: 'red' }}>*</span>
                    </label>
                    <input
                        type="text"
                        id="firstName"
                        name="firstName"
                        value={formData.firstName}
                        placeholder="John"
                        onChange={handleChange}
                        required
                    />
                </div>
                <div className="last-name-input">
                    <label htmlFor="lastName">
                        Last Name<span style={{ color: 'red' }}>*</span>
                    </label>
                    <input
                        type="text"
                        id="lastName"
                        name="lastName"
                        value={formData.lastName}
                        placeholder="Doe"
                        onChange={handleChange}
                        required
                    />
                </div>
                <div className="email-input">
                    <label htmlFor="email">
                        Email<span style={{ color: 'blue' }}>*</span>
                    </label>
                    <input
                        type="email"
                        id="email"
                        name="email"
                        value={formData.email}
                        placeholder="name@gmail.com"
                        onChange={handleChange}
                    />
                </div>
                <div className="number-input">
                    <label htmlFor="phone">
                        Phone Number<span style={{ color: 'blue' }}>*</span>
                    </label>
                    <input
                        type="tel"
                        id="phone"
                        name="phone"
                        maxLength={12}
                        value={formatPhoneNumber(formData.phone)}
                        placeholder="XXX-XXX-XXXX"
                        onChange={handleChange}
                    />
                </div>
                <div className="user-type-input">
                    <label htmlFor="category">
                        Are you interested in our Driver, Host, or Parking Lot service?<span style={{ color: 'red' }}>*</span>
                    </label>
                    <select
                        id="category"
                        name="category"
                        value={formData.category}
                        onChange={handleChange}>
                        <option value="Driver">Driver</option>
                        <option value="Host">Host</option>
                        <option value="Parking Lot">Parking Lot</option>
                    </select>
                </div>
                <div className="interests-input">
                    <label htmlFor="interests">Where are you located?</label>
                    <input
                        id="location"
                        name="location"
                        value={formData.location}
                        onChange={handleChange}
                        placeholder="Astoria, Queens"
                    />
                </div>
                <div className="interests-input">
                    <label htmlFor="interests">What interests you about Parket? Any questions for us?</label>
                    <textarea
                        id="interests"
                        name="interests"
                        value={formData.interests}
                        onChange={handleChange}
                        placeholder="I want to rent out my driveway with 2 spots, how much can I make? Can you help with...?"
                    />
                </div>
                <div className="submit-btn">
                    <button type="submit">Submit</button>
                </div>
            </form>
        </div>
    )
}
