import React from 'react'
import bannerCar from '../../assets/banner-car.png'
import './Terms.css'
// 1. Introduction
import { introductionTextParagraph1, introductionTextParagraph2, introductionTextParagraph3, introductionTextParagraph4 } from './terms-text'
// 2. Renter Terms
import { renterTermsText2_1, renterTermsText2_2 } from './terms-text'
// 3. Making a Request and Booking a Listing
import {
    bookingAndRequests3_1,
    bookingAndRequests3_1_ex_i,
    bookingAndRequests3_1_ex_ii,
    bookingAndRequests3_1_ex_iii,
    bookingAndRequests3_1_ex_iv,
    bookingAndRequests3_1_ex_v,
    bookingAndRequests3_1_ex_vi,
    bookingAndRequests3_1_ex_vii,
    bookingAndRequests3_2,
    bookingAndRequests3_3,
    bookingAndRequests3_4_ex_i,
    bookingAndRequests3_4_ex_ii,
    bookingAndRequests3_4_ex_iii,
    bookingAndRequests3_4_ex_iv,
    bookingAndRequests3_4_ex_v,
    bookingAndRequests3_4_p1,
    bookingAndRequests3_4_p2,
} from './terms-text'
// 4. Payments
import { paymentsText4_1, paymentsText4_2, paymentsText4_3, paymentsText4_4 } from './terms-text'
// 5. Renter Cancelation Policy
import {
    renterCancelationPolicyText5_1_p1,
    renterCancelationPolicyText5_1_p2,
    renterCancelationPolicyText5_1_ex_i,
    renterCancelationPolicyText5_1_ex_ii,
    renterCancelationPolicyText5_1_ex_iii,
    renterCancelationPolicyText5_2,
    renterCancelationPolicyText5_3,
} from './terms-text'
// 6. Listings and Host
import {
    listingAndHostInteractionsText6_1,
    listingAndHostInteractionsText6_2,
    listingAndHostInteractionsText6_2_ex_i,
    listingAndHostInteractionsText6_2_ex_ii,
    listingAndHostInteractionsText6_2_ex_iii,
    listingAndHostInteractionsText6_2_ex_iv,
    listingAndHostInteractionsText6_3,
} from './terms-text'
// 7. Host Terms
import {
    hostOnboarding7_1,
    hostOnboarding7_1_ex_i,
    hostOnboarding7_1_ex_ii,
    hostOnboarding7_1_ex_iii,
    listingEligibility7_2,
    insuranceRequirement7_3,
    describingYourSpace7_4,
    imageGuidelines7_5,
    settingAvailabilities7_6,
    settingRates7_7,
    stripeVerification7_8_p1,
    stripeVerification7_8_ex_i,
    stripeVerification7_8_ex_ii,
    stripeVerification7_8_ex_iii,
    stripeVerification7_8_ex_iv,
    stripeVerification7_8_ex_v,
    stripeVerification7_8_ex_vi,
    stripeVerification7_8_p2,
    parketApproval7_9,
} from './terms-text'
// 8. Managing Your Listing
import {
    managingYourListing8_1_p1,
    managingYourListing8_1_ex_i,
    managingYourListing8_1_ex_ii,
    managingYourListing8_1_ex_iii,
    managingYourListing8_1_ex_iv,
    managingYourListing8_1_ex_v,
    managingYourListing8_1_ex_vi,
    managingYourListing8_1_ex_vii,
    managingYourListing8_1_ex_viii,
    managingYourListing8_1_ex_ix,
    managingYourListing8_1_ex_x,
    managingYourListing8_1_ex_xi,
    managingYourListing8_1_p2,
    managingYourListing8_2,
    managingYourListing8_3,
} from './terms-text'
// 9. Accepting Requests and Managing Bookings
import {
    acceptingRequests9_1_p1,
    acceptingRequests9_1_ex_i,
    acceptingRequests9_1_ex_ii,
    acceptingRequests9_1_p2,
    acceptingRequests9_2,
    acceptingRequests9_3,
    acceptingRequests9_3_ex_I,
    acceptingRequests9_3_ex_II,
    acceptingRequests9_3_ex_III,
    acceptingRequests9_3_ex_IV,
    acceptingRequests9_3_ex_V,
    acceptingRequests9_4,
    acceptingRequests9_5,
} from './terms-text'
// 10. Host Cancelation Policy
import { hostCancelationPolicy10_1, hostCancelationPolicy10_2, hostCancelationPolicy10_3 } from './terms-text'
// 11. Payouts
import { payouts11_1, payouts11_2, payouts11_3 } from './terms-text'
// 12. General Terms
import { accountsRegistration12_1, accountsTermination12_2 } from './terms-text'
// 13. Fee Structure
import { feeStructure13_1, feeStructure13_2, feeStructure13_3 } from './terms-text'
// 14. Overstay Policy
import { overstayPolicy14_1, overstayPolicy14_2, overstayPolicy14_3, overstayPolicy14_4 } from './terms-text'
// 15. Disputes
import { disputes15 } from './terms-text'
// 16. Content
import { contentPolicy16_1, contentRestrictions16_2 } from './terms-text'
// 17. Application License
import {
    applicationLicense17_1,
    applicationLicense17_2,
    applicationLicense17_2_ex_I,
    applicationLicense17_2_ex_II,
    applicationLicense17_2_ex_III,
    applicationLicense17_3,
    applicationLicense17_4,
    applicationLicense17_5,
} from './terms-text'
// 18. Use Restrictions
import { useRestrictions18_1, useRestrictions18_2, useRestrictions18_3, useRestrictions18_4 } from './terms-text'
// 19. Reporting Violations
import { reportingViolations19 } from './terms-text'
// 20. Indemnity
import { indemnity20, indemnity20_ex_I, indemnity20_ex_II, indemnity20_ex_III, indemnity20_ex_IV, indemnity20_ex_V, indemnity20_note } from './terms-text'
// 21. Arbitration
import { arbitration21_1, arbitration21_2 } from './terms-text'
// 22. Class Action Waiver
import { classActionWaiver22 } from './terms-text'
// 23. Severability
import { severability23 } from './terms-text'

export default function Terms() {
    // The focused section, used by Table of Contents to determine which item to highlight/active style
    const [activeSection, setActiveSection] = React.useState('')

    // Store refs to all sections for Observer
    const sectionRefs = React.useRef(new Map())

    // Handles setting which section we are focused on.
    React.useEffect(() => {
        const observerOptions = {
            root: null,
            // Adjusting to trigger when header is at the topmost part of the screen
            // Currently the section header that is on the top 10% of the screen is the focused section
            rootMargin: '0px 0px -90% 0px',
            threshold: 0,
        }

        // Replace URL section (ex: localhost:3000/terms-and-conditions#section6)
        const observerCallback = (entries) => {
            entries.forEach((entry) => {
                if (entry.isIntersecting) {
                    const sectionId = entry.target.id
                    setActiveSection(sectionId)
                    window.history.replaceState(null, '', `#${sectionId}`)
                }
            })
        }

        // Provides a way to asynchronously observe changes in the intersection of a target element with an ancestor element or with a top-level document's viewport.
        const observer = new IntersectionObserver(observerCallback, observerOptions)

        // Observe each section
        sectionRefs.current.forEach((ref) => {
            if (ref) observer.observe(ref)
        })

        // Clean up observer on component dismount
        return () => {
            sectionRefs.current.forEach((ref) => {
                if (ref) observer.unobserve(ref)
            })
        }
    }, [])

    // Scrolls to hashed string if user routed with a hash in the URL (ex: localhost:3000/page#section6)
    React.useEffect(() => {
        const hash = window.location.hash.substring(1)
        if (hash) {
            const element = document.getElementById(hash)
            if (element) {
                element.scrollIntoView({ behavior: 'smooth', block: 'start' })
                setActiveSection(hash)
            }
        }
    }, [])

    // Register all section references to #id
    const registerSectionRef = (sectionId, ref) => {
        sectionRefs.current.set(sectionId, ref)
    }

    return (
        <div className="terms-wrapper">
            <div className="terms-banner">
                <h1>Terms & Conditions</h1>
                <h2>Sloanie, Inc. DBA Parket</h2>
                <p>Last Updated: Sat May 24, 2024</p>
            </div>

            <div className="terms-page-container">
                <div className="terms-table-of-contents">
                    <h2>Table of Contents</h2>
                    <ul>
                        {[
                            { id: 'section1', title: '1. Introduction' },
                            { id: 'section2', title: '2. Renter Terms' },
                            { id: 'section3', title: '3. Creating a Booking/Request' },
                            { id: 'section4', title: '4. Payments' },
                            { id: 'section5', title: '5. Renter Cancelation Policy' },
                            { id: 'section6', title: '6. Interacting with Hosts/Listings' },
                            { id: 'section7', title: '7. Host Terms' },
                            { id: 'section8', title: '8. Managing Your Listing' },
                            { id: 'section9', title: '9. Managing Requests/Bookings' },
                            { id: 'section10', title: '10. Host Cancelation Policy' },
                            { id: 'section11', title: '11. Payouts' },
                            { id: 'section12', title: '12. General Terms' },
                            { id: 'section13', title: '13. Fee Structure' },
                            { id: 'section14', title: '14. Overstay Policy' },
                            { id: 'section15', title: '15. Disputes' },
                            { id: 'section16', title: '16. Content' },
                            { id: 'section17', title: '17. Application License' },
                            { id: 'section18', title: '18. Use Restrictions' },
                            { id: 'section19', title: '19. Reporting Violations' },
                            { id: 'section20', title: '20. Indemnity' },
                            { id: 'section21', title: '21. Arbitration' },
                            { id: 'section22', title: '22. Class Action Waiver' },
                            { id: 'section23', title: '23. Severability' },
                        ].map((item) => (
                            <li
                                key={item.id}
                                className={activeSection === item.id ? 'active' : ''}>
                                <a
                                    href={`#${item.id}`}
                                    onClick={(e) => {
                                        e.preventDefault()
                                        document.getElementById(item.id).scrollIntoView({
                                            behavior: 'smooth',
                                            block: 'start',
                                        })
                                    }}>
                                    {item.title}
                                </a>
                            </li>
                        ))}
                    </ul>
                </div>

                <div className="terms-content">
                    <div
                        id="section1"
                        ref={(ref) => registerSectionRef('section1', ref)}
                        className="terms-section"
                        style={{ padding: '0px 20px' }}>
                        <h2>1. Introduction</h2>
                        <p>{introductionTextParagraph1}</p>
                        <br />
                        <p>{introductionTextParagraph2}</p>
                        <br />
                        <p>{introductionTextParagraph3}</p>
                        <br />
                        <p>{introductionTextParagraph4}</p>
                    </div>
                    <div
                        id="section2"
                        ref={(ref) => registerSectionRef('section2', ref)}
                        className="terms-section">
                        <h2>2. Renter Terms</h2>
                        <div className="terms-examples-column">
                            <span>2.1. Searching</span>
                            <p>{renterTermsText2_1}</p>
                        </div>
                        <br />
                        <div className="terms-examples-column">
                            <span>2.2. Listing Information</span>
                            <p>{renterTermsText2_2}</p>
                        </div>
                    </div>
                    <div
                        id="section3"
                        ref={(ref) => registerSectionRef('section3', ref)}
                        className="terms-section">
                        <h2>3. Creating a Booking/Request</h2>
                        <div className="terms-examples-column">
                            <span>3.1. Types of Requests and Bookings</span>
                            <p>{bookingAndRequests3_1}</p>
                        </div>
                        <br />
                        <div className="terms-examples-row">
                            <div className="terms-example-group">
                                <span>{'I. '}</span>
                                <p>{bookingAndRequests3_1_ex_i}</p>
                            </div>
                            <div className="terms-example-group">
                                <span>{'II.'}</span>
                                <p>{bookingAndRequests3_1_ex_ii}</p>
                            </div>
                            <div className="terms-example-group">
                                <span>{'III.'}</span>
                                <p>{bookingAndRequests3_1_ex_iii}</p>
                            </div>
                            <div className="terms-example-group">
                                <span>{'IV.'}</span>
                                <p>{bookingAndRequests3_1_ex_iv}</p>
                            </div>
                            <div className="terms-example-group">
                                <span>{'V.'}</span>
                                <p>{bookingAndRequests3_1_ex_v}</p>
                            </div>
                            <div className="terms-example-group">
                                <span>{'VI.'}</span>
                                <p>{bookingAndRequests3_1_ex_vi}</p>
                            </div>
                            <div className="terms-example-group">
                                <span>{'VII.'}</span>
                                <p>{bookingAndRequests3_1_ex_vii}</p>
                            </div>
                        </div>
                        <br />
                        <div className="terms-examples-column">
                            <span>3.2. Making a Request</span>
                            <p>{bookingAndRequests3_2}</p>
                        </div>
                        <br />
                        <div className="terms-examples-column">
                            <span>3.3. Accepted Requests</span>
                            <p>{bookingAndRequests3_3}</p>
                        </div>
                        <br />
                        <div className="terms-examples-column">
                            <span>3.4. Vehicle Information</span>
                            <p>{bookingAndRequests3_4_p1}</p>
                        </div>
                        <br />
                        <div className="terms-examples-row">
                            <div className="terms-example-group">
                                <span>{'I.'}</span>
                                <p>{bookingAndRequests3_4_ex_i}</p>
                            </div>
                            <div className="terms-example-group">
                                <span>{'II.'}</span>
                                <p>{bookingAndRequests3_4_ex_ii}</p>
                            </div>
                            <div className="terms-example-group">
                                <span>{'III.'}</span>
                                <p>{bookingAndRequests3_4_ex_iii}</p>
                            </div>
                            <div className="terms-example-group">
                                <span>{'IV.'}</span>
                                <p>{bookingAndRequests3_4_ex_iv}</p>
                            </div>
                            <div className="terms-example-group">
                                <span>{'V.'}</span>
                                <p>{bookingAndRequests3_4_ex_v}</p>
                            </div>
                        </div>
                        <br />
                        <p>{bookingAndRequests3_4_p2}</p>
                    </div>
                    <div
                        id="section4"
                        ref={(ref) => registerSectionRef('section4', ref)}
                        className="terms-section">
                        <h2>4. Payments</h2>
                        <div className="terms-examples-column">
                            <span>4.1. Payment Authorization</span>
                            <p>{paymentsText4_1}</p>
                        </div>
                        <br />
                        <div className="terms-examples-column">
                            <span>4.2. Payment Processing</span>
                            <p>{paymentsText4_2}</p>
                        </div>
                        <br />
                        <div className="terms-examples-column">
                            <span>4.3. Reoccuring Payments</span>
                            <p>{paymentsText4_3}</p>
                        </div>
                        <br />
                        <div className="terms-examples-column">
                            <span>4.4. Updates to Payment Method</span>
                            <p>{paymentsText4_4}</p>
                        </div>
                    </div>
                    <div
                        id="section5"
                        ref={(ref) => registerSectionRef('section5', ref)}
                        className="terms-section">
                        <h2>5. Renter Cancelation Policy</h2>
                        <div className="terms-examples-column">
                            <span>5.1. General Renter Cancelation Policy</span>
                            <p>{renterCancelationPolicyText5_1_p1}</p>
                            <br />
                            <div className="terms-examples-column">
                                <span>{`I. Grace Period Cancelation`}</span>
                                <p>{renterCancelationPolicyText5_1_ex_i}</p>
                            </div>
                            <br />
                            <div className="terms-examples-column">
                                <span>{`II. Post-Grace Period Cancelation`}</span>
                                <p>{renterCancelationPolicyText5_1_ex_ii}</p>
                            </div>
                            <br />
                            <div className="terms-examples-column">
                                <span>{`III. After Booking Start Time`}</span>
                                <p>{renterCancelationPolicyText5_1_ex_iii}</p>
                            </div>
                        </div>
                        <br />
                        <p>{renterCancelationPolicyText5_1_p2}</p>
                        <br />
                        <div className="terms-examples-column">
                            <span>5.2. Cancelation of Rolling Bookings</span>
                            <p>{renterCancelationPolicyText5_2}</p>
                        </div>
                        <br />
                        <div className="terms-examples-column">
                            <span>5.3. Host Cancelations</span>
                            <p>{renterCancelationPolicyText5_3}</p>
                        </div>
                    </div>
                    <div
                        id="section6"
                        ref={(ref) => registerSectionRef('section6', ref)}
                        className="terms-section">
                        <h2>6. Interacting with Hosts/Listings</h2>
                        <div className="terms-examples-column">
                            <span>6.1. Shared Spaces</span>
                            <p>{listingAndHostInteractionsText6_1}</p>
                        </div>
                        <br />
                        <div className="terms-examples-column">
                            <span>6.2. Your Responsibilities at the Listing</span>
                            <p>{listingAndHostInteractionsText6_2}</p>
                        </div>
                        <br />
                        <div className="terms-examples-column">
                            <span>{`I. Designated Area `}</span>
                            <p> {listingAndHostInteractionsText6_2_ex_i}</p>
                        </div>
                        <br />
                        <div className="terms-examples-column">
                            <span>{`II. Cleanliness`}</span>
                            <p> {listingAndHostInteractionsText6_2_ex_ii}</p>
                        </div>
                        <br />
                        <div className="terms-examples-column">
                            <span>{`III. Obstruction-Free Parking`}</span>
                            <p> {listingAndHostInteractionsText6_2_ex_iii}</p>
                        </div>
                        <br />
                        <div className="terms-examples-column">
                            <span>{`IV. Proper Use`}</span>
                            <p>{listingAndHostInteractionsText6_2_ex_iv}</p>
                        </div>
                        <br />
                        <div className="terms-examples-column">
                            <span>6.3. Interacting with Hosts: </span>
                            <p>{listingAndHostInteractionsText6_3}</p>
                        </div>
                    </div>
                    <div
                        id="section7"
                        ref={(ref) => registerSectionRef('section7', ref)}
                        className="terms-section">
                        <h2>7. Host Terms</h2>
                        <div className="terms-examples-column">
                            <span>7.1. Onboarding Process</span>
                            <p>{hostOnboarding7_1}</p>
                        </div>
                        <br />
                        <div className="terms-examples-column">
                            <span>{`I. Parking Space Details`}</span>
                            <p>{hostOnboarding7_1_ex_i}</p>
                        </div>
                        <br />
                        <div className="terms-examples-column">
                            <span>{`II. Banking Information`}</span>
                            <p>{hostOnboarding7_1_ex_ii}</p>
                        </div>
                        <br />
                        <div className="terms-examples-column">
                            <span>{`III. Application Review and Verification`}</span>
                            <p>{hostOnboarding7_1_ex_iii}</p>
                        </div>
                        <br />
                        <div className="terms-examples-column">
                            <span>7.2. Listing Eligibility</span>
                            <p>{listingEligibility7_2}</p>
                        </div>
                        <br />
                        <div className="terms-examples-column">
                            <span>7.3. Insurance Requirement</span>
                            <p>{insuranceRequirement7_3}</p>
                        </div>
                        <br />
                        <div className="terms-examples-column">
                            <span>7.4. Describing Your Space</span>
                            <p>{describingYourSpace7_4}</p>
                        </div>
                        <br />
                        <div className="terms-examples-column">
                            <span>7.5. Image Guidelines</span>
                            <p> {imageGuidelines7_5}</p>
                        </div>
                        <br />
                        <div className="terms-examples-column">
                            <span>7.6. Setting Availabilities</span>
                            <p>{settingAvailabilities7_6}</p>
                        </div>
                        <br />
                        <div className="terms-examples-column">
                            <span>7.7. Setting Rates</span>
                            <p>{settingRates7_7}</p>
                        </div>
                        <br />
                        <div className="terms-examples-column">
                            <span>7.8. Stripe Verification</span>
                            <p>{stripeVerification7_8_p1}</p>
                        </div>
                        <br />
                        <div className="terms-examples-row">
                            <div className="terms-example-group">
                                <span>{'I.'}</span>
                                <p>{stripeVerification7_8_ex_i}</p>
                            </div>
                            <div className="terms-example-group">
                                <span>{'II.'}</span>
                                <p>{stripeVerification7_8_ex_ii}</p>
                            </div>
                            <div className="terms-example-group">
                                <span>{'III.'}</span>
                                <p>{stripeVerification7_8_ex_iii}</p>
                            </div>
                            <div className="terms-example-group">
                                <span>{'IV.'}</span>
                                <p>{stripeVerification7_8_ex_iv}</p>
                            </div>
                            <div className="terms-example-group">
                                <span>{'V.'}</span>
                                <p>{stripeVerification7_8_ex_v}</p>
                            </div>
                            <div className="terms-example-group">
                                <span>{'VI.'}</span>
                                <p>{stripeVerification7_8_ex_vi}</p>
                            </div>
                        </div>
                        <br />
                        <p>{stripeVerification7_8_p2}</p>
                        <br />
                        <div className="terms-examples-column">
                            <span>7.9. Parket Approval</span>
                            <p>{parketApproval7_9}</p>
                        </div>
                    </div>

                    <div
                        id="section8"
                        ref={(ref) => registerSectionRef('section8', ref)}
                        className="terms-section">
                        <h2>8. Managing Your Listing</h2>
                        <div className="terms-examples-column">
                            <span>8.1. Updating Information About Your Listing</span>
                            {managingYourListing8_1_p1}
                        </div>
                        <br />
                        <div className="terms-examples-row">
                            <div className="terms-example-group">
                                <span>{'I.'}</span>
                                <p>{managingYourListing8_1_ex_i}</p>
                            </div>
                            <div className="terms-example-group">
                                <span>{'II.'}</span>
                                <p>{managingYourListing8_1_ex_ii}</p>
                            </div>
                            <div className="terms-example-group">
                                <span>{'III.'}</span>
                                <p>{managingYourListing8_1_ex_iii}</p>
                            </div>
                            <div className="terms-example-group">
                                <span>{'IV.'}</span>
                                <p>{managingYourListing8_1_ex_iv}</p>
                            </div>
                            <div className="terms-example-group">
                                <span>{'V.'}</span>
                                <p>{managingYourListing8_1_ex_v}</p>
                            </div>
                            <div className="terms-example-group">
                                <span>{'VI.'}</span>
                                <p>{managingYourListing8_1_ex_vi}</p>
                            </div>
                            <div className="terms-example-group">
                                <span>{'VII.'}</span>
                                <p>{managingYourListing8_1_ex_vii}</p>
                            </div>
                            <div className="terms-example-group">
                                <span>{'VIII.'}</span>
                                <p>{managingYourListing8_1_ex_viii}</p>
                            </div>
                            <div className="terms-example-group">
                                <span>{'IX.'}</span>
                                <p>{managingYourListing8_1_ex_ix}</p>
                            </div>
                            <div className="terms-example-group">
                                <span>{'X.'}</span>
                                <p>{managingYourListing8_1_ex_x}</p>
                            </div>
                            <div className="terms-example-group">
                                <span>{'XI.'}</span>
                                <p>{managingYourListing8_1_ex_xi}</p>
                            </div>
                        </div>
                        <br />
                        <p>{managingYourListing8_1_p2}</p>
                        <br />
                        <div className="terms-examples-column">
                            <span>8.2. Deactivating and Activating Your Listing</span>
                            <p>{managingYourListing8_2}</p>
                        </div>
                        <br />
                        <div className="terms-examples-column">
                            <span>8.3. Deleting Your Listing</span>
                            <p>{managingYourListing8_3}</p>
                        </div>
                    </div>

                    <div
                        id="section9"
                        ref={(ref) => registerSectionRef('section9', ref)}
                        className="terms-section">
                        <h2>9. Managing Requests/Bookings</h2>
                        <div className="terms-examples-column">
                            <span>9.1. Manually Accepting Requests</span>
                            <p>{acceptingRequests9_1_p1}</p>
                        </div>
                        <br />
                        <div className="terms-examples-row">
                            <div className="terms-example-group">
                                <span>{'I.'}</span>
                                <p>{acceptingRequests9_1_ex_i}</p>
                            </div>
                            <div className="terms-example-group">
                                <span>{'II.'}</span>
                                <p>{acceptingRequests9_1_ex_ii}</p>
                            </div>
                        </div>
                        <br />
                        <p>{acceptingRequests9_1_p2}</p>
                        <br />
                        <div className="terms-examples-column">
                            <span>9.2. Auto-Accepting Requests</span>
                            <p>{acceptingRequests9_2}</p>
                        </div>
                        <br />
                        <div className="terms-examples-column">
                            <span>9.3. Your Responsibilities as a Host</span>
                            <p>{acceptingRequests9_3}</p>
                        </div>
                        <br />
                        <div className="terms-examples-row">
                            <div className="terms-example-group">
                                <span>{'I.'}</span>
                                <p>{acceptingRequests9_3_ex_I}</p>
                            </div>
                            <div className="terms-example-group">
                                <span>{'II.'}</span>
                                <p>{acceptingRequests9_3_ex_II}</p>
                            </div>
                            <div className="terms-example-group">
                                <span>{'III.'}</span>
                                <p>{acceptingRequests9_3_ex_III}</p>
                            </div>
                            <div className="terms-example-group">
                                <span>{'IV.'}</span>
                                <p>{acceptingRequests9_3_ex_IV}</p>
                            </div>
                            <div className="terms-example-group">
                                <span>{'V.'}</span>
                                <p>{acceptingRequests9_3_ex_V}</p>
                            </div>
                        </div>
                        <br />
                        <div className="terms-examples-column">
                            <span>9.4. Dealing with Multiple Cars</span>
                            <p>{acceptingRequests9_4}</p>
                        </div>
                        <br />
                        <div className="terms-examples-column">
                            <span>9.5. Damage to Person or Property</span>
                            <p>{acceptingRequests9_5}</p>
                        </div>
                    </div>

                    <div
                        id="section10"
                        ref={(ref) => registerSectionRef('section10', ref)}
                        className="terms-section">
                        <h2>10. Host Cancelation Policy</h2>
                        <div className="terms-examples-column">
                            <span>10.1. General Host Cancelation Policy</span>
                            <p>{hostCancelationPolicy10_1}</p>
                        </div>
                        <br />
                        <div className="terms-examples-column">
                            <span>10.2. Cancelation of Rolling Bookings</span>
                            <p>{hostCancelationPolicy10_2}</p>
                        </div>
                        <br />
                        <div className="terms-examples-column">
                            <span>10.3. Renter Cancelations</span>
                            <p>{hostCancelationPolicy10_3}</p>
                        </div>
                    </div>

                    <div
                        id="section11"
                        ref={(ref) => registerSectionRef('section11', ref)}
                        className="terms-section">
                        <h2>11. Payouts</h2>
                        <div className="terms-examples-column">
                            <span>11.1. Payout Schedule</span>
                            <p>{payouts11_1}</p>
                        </div>
                        <br />
                        <div className="terms-examples-column">
                            <span>11.2. Handling Issues with Payouts</span>
                            <p>{payouts11_2}</p>
                        </div>
                        <br />
                        <div className="terms-examples-column">
                            <span>11.3. Updating Your Bank Account Information</span>
                            <p>{payouts11_3}</p>
                        </div>
                    </div>

                    <div
                        id="section12"
                        ref={(ref) => registerSectionRef('section12', ref)}
                        className="terms-section">
                        <h2>12. General Terms</h2>
                        <div className="terms-examples-column">
                            <span>12.1. Accounts - Registration</span>
                            <p>{accountsRegistration12_1}</p>
                        </div>
                        <br />
                        <div className="terms-examples-column">
                            <span>12.2. Accounts - Termination</span>
                            <p>{accountsTermination12_2}</p>
                        </div>
                    </div>

                    <div
                        id="section13"
                        ref={(ref) => registerSectionRef('section13', ref)}
                        className="terms-section">
                        <h2>13. Fee Structure</h2>
                        <div className="terms-examples-column">
                            <span>13.1. How we set prices using Host’s rates</span>
                            <p>{feeStructure13_1}</p>
                        </div>
                        <br />
                        <div className="terms-examples-column">
                            <span>13.2. Defining a Parket day</span>
                            <p> {feeStructure13_2}</p>
                        </div>
                        <br />
                        <div className="terms-examples-column">
                            <span>13.3. Breakdown of fees</span>
                            <p>{feeStructure13_3}</p>
                        </div>
                    </div>

                    <div
                        id="section14"
                        ref={(ref) => registerSectionRef('section14', ref)}
                        className="terms-section">
                        <h2>14. Overstay Policy</h2>
                        <div className="terms-examples-column">
                            <span>14.1. Renter Expectations</span>
                            <p> {overstayPolicy14_1}</p>
                        </div>
                        <br />
                        <div className="terms-examples-column">
                            <span>14.2. Communicating with the Renter and Parket</span>
                            <p>{overstayPolicy14_2}</p>
                        </div>
                        <br />
                        <div className="terms-examples-column">
                            <span>14.3. Host Actions Post-Grace Period</span>
                            <p> {overstayPolicy14_3}</p>
                        </div>
                        <br />
                        <div className="terms-examples-column">
                            <span>14.4. Overstay Liability</span>
                            <p> {overstayPolicy14_4}</p>
                        </div>
                    </div>

                    <div
                        id="section15"
                        ref={(ref) => registerSectionRef('section15', ref)}
                        className="terms-section">
                        <h2>15. Disputes</h2>
                        <p>{disputes15}</p>
                    </div>

                    <div
                        id="section16"
                        ref={(ref) => registerSectionRef('section16', ref)}
                        className="terms-section">
                        <h2>16. Content</h2>
                        <div className="terms-examples-column">
                            <span>16.1. Content Policy</span>
                            <p>{contentPolicy16_1}</p>
                        </div>
                        <br />
                        <div className="terms-examples-column">
                            <span>16.2. Content Restrictions</span>
                            <p>{contentRestrictions16_2}</p>
                        </div>
                    </div>

                    <div
                        id="section17"
                        ref={(ref) => registerSectionRef('section17', ref)}
                        className="terms-section">
                        <h2>17. Application License</h2>
                        <div className="terms-examples-column">
                            <span>17.1. Grant of License</span>
                            <p>{applicationLicense17_1}</p>
                        </div>
                        <br />
                        <div className="terms-examples-column">
                            <span>17.2. License Restrictions</span>
                            <p>{applicationLicense17_2}</p>
                        </div>
                        <br />
                        <div className="terms-examples-row">
                            <div className="terms-example-group">
                                <span>{'I.'}</span>
                                <p>{applicationLicense17_2_ex_I}</p>
                            </div>
                            <div className="terms-example-group">
                                <span>{'II.'}</span>
                                <p>{applicationLicense17_2_ex_II}</p>
                            </div>
                            <div className="terms-example-group">
                                <span>{'III.'}</span>
                                <p>{applicationLicense17_2_ex_III}</p>
                            </div>
                        </div>
                        <br />
                        <div className="terms-examples-column">
                            <span>17.3. Copyright</span>
                            <p>{applicationLicense17_3}</p>
                        </div>
                        <br />
                        <div className="terms-examples-column">
                            <span>17.4. License Termination</span>
                            <p>{applicationLicense17_4}</p>
                        </div>
                        <br />
                        <div className="terms-examples-column">
                            <span>17.5. License Amendment: </span>
                            <p>{applicationLicense17_5}</p>
                        </div>
                    </div>

                    <div
                        id="section18"
                        ref={(ref) => registerSectionRef('section18', ref)}
                        className="terms-section">
                        <h2>18. Use Restrictions</h2>
                        <div className="terms-examples-column">
                            <span>18.1. Unauthorized Data Extraction</span>
                            <p>{useRestrictions18_1}</p>
                        </div>
                        <br />
                        <div className="terms-examples-column">
                            <span>18.2. Solicitation</span>
                            <p>{useRestrictions18_2}</p>
                        </div>
                        <br />
                        <div className="terms-examples-column">
                            <span>18.3. Exploitation of Services</span>
                            <p>{useRestrictions18_3}</p>
                        </div>
                        <br />
                        <div className="terms-examples-column">
                            <span>18.4. Unauthorized Access</span>
                            <p> {useRestrictions18_4}</p>
                        </div>
                    </div>

                    <div
                        id="section19"
                        ref={(ref) => registerSectionRef('section19', ref)}
                        className="terms-section">
                        <h2>19. Reporting Violations</h2>
                        <p>{reportingViolations19}</p>
                    </div>

                    <div
                        id="section20"
                        ref={(ref) => registerSectionRef('section20', ref)}
                        className="terms-section">
                        <h2>20. Indemnity</h2>
                        <p>{indemnity20}</p>
                        <br />
                        <div className="terms-examples-row">
                            <div className="terms-example-group">
                                <span>{'I.'}</span>
                                <p>{indemnity20_ex_I}</p>
                            </div>
                            <div className="terms-example-group">
                                <span>{'II.'}</span>
                                <p>{indemnity20_ex_II}</p>
                            </div>
                            <div className="terms-example-group">
                                <span>{'III.'}</span>
                                <p>{indemnity20_ex_III}</p>
                            </div>
                            <div className="terms-example-group">
                                <span>{'IV.'}</span>
                                <p>{indemnity20_ex_IV}</p>
                            </div>
                            <div className="terms-example-group">
                                <span>{'V.'}</span>
                                <p>{indemnity20_ex_V}</p>
                            </div>
                        </div>
                        <br />
                        <p>{indemnity20_note}</p>
                    </div>

                    <div
                        id="section21"
                        ref={(ref) => registerSectionRef('section21', ref)}
                        className="terms-section">
                        <h2>21. Arbitration</h2>
                        <div className="terms-examples-column">
                            <span>21.1. Agreement to Arbitrate</span>
                            <p>{arbitration21_1} </p>
                        </div>
                        <br />
                        <div className="terms-examples-column">
                            <span>21.2. Arbitration Rules</span>
                            <p>{arbitration21_2} </p>
                        </div>
                    </div>

                    <div
                        id="section22"
                        ref={(ref) => registerSectionRef('section22', ref)}
                        className="terms-section">
                        <h2>22. Class Action Waiver</h2>
                        <p>{classActionWaiver22}</p>
                    </div>

                    <div
                        id="section23"
                        ref={(ref) => registerSectionRef('section23', ref)}
                        className="terms-section">
                        <h2>23. Severability</h2>
                        <p>{severability23}</p>
                    </div>
                </div>
            </div>
            <div className="terms-questions-container">
                <h2>Any questions or concerns? Feel free to contact us at anytime.</h2>
                <h2 style={{ marginBottom: 10, color: '#ffef00' }}>contact@joinparket.com</h2>
                <img
                    src={bannerCar}
                    alt=""
                />
            </div>
        </div>
    )
}
