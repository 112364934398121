import React from 'react'
import { Link } from 'react-router-dom'
import './Navbar.css'
import logo from '../../assets/parket-logo-may2024.png'
import { useLocation } from 'react-router-dom'

export default function Navbar() {
    const location = useLocation()
    if (location?.pathname === '/return') {
        return null
    }

    return (
        <nav className="navbar-container">
            <Link
                to="/"
                aria-label="Logo home page">
                <div className="logo-container">
                    <img
                        className="logo-img"
                        src={logo}
                        alt="Parket"
                    />
                </div>
            </Link>
            <div className="choices-container">
                <Link
                    className="nav-link"
                    to="contact"
                    aria-label="Waitlist link">
                    Join Waitlist
                </Link>
                <Link
                    className="nav-link"
                    to="about"
                    aria-label="About us link">
                    About Us
                </Link>
            </div>
        </nav>
    )
}
